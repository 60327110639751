import { createSlice } from "@reduxjs/toolkit"
import { ChangeHistoryItem } from "../../components/change-history/domain"
import { fetchChangeHistory } from "./actions"

export interface ChangeHistoryStateInterface {
  changeHistory: ChangeHistoryItem[]
}

const initialState: ChangeHistoryStateInterface = {
  changeHistory: [],
}

const changeHistorySlice = createSlice({
  name: "changeHistory",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChangeHistory.fulfilled, (state, { payload }) => {
      state.changeHistory = payload
    })
  },
})

export default changeHistorySlice.reducer
