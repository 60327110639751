import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import {SERVICE_URL} from "../../utils/service-url";
import {AxiosError, AxiosResponse} from "axios";

export const initialize = createAction('init')
export const globalErrorAction = createAction<boolean>("global_error")

export const fetchUser = createAsyncThunk("fetch/user", (undefined, {rejectWithValue}) =>
    axios
        .get(`${SERVICE_URL}/api/who`)
        .then((response: AxiosResponse) => response.data)
        .catch((err: AxiosError) => rejectWithValue({message: err.message, name: err.name, status: err.response?.status}))

)