import axios from "../../utils/axios";
import {AxiosError, AxiosResponse} from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {SERVICE_URL} from "../../utils/service-url";


export const fetchDeploymentProjects = createAsyncThunk("fetch/deploymentProjects", (undefined, {rejectWithValue}) =>
    axios
        .get(`${SERVICE_URL}/api/deployment-projects`)
        .then((response: AxiosResponse) => response.data)
        .catch((err: AxiosError) => rejectWithValue({message: err.message, name: err.name, status: err.response?.status}))
)

export const fetchParentTasks = createAsyncThunk("fetch/parentTasks", (undefined, {rejectWithValue}) =>
    axios
        .get(`${SERVICE_URL}/api/parent-tasks?refresh=true`)
        .then((response: AxiosResponse) => response.data)
        .catch((err: AxiosError) => rejectWithValue({message: err.message, name: err.name, status: err.response?.status}))
)