import "./deployment-projects.scss"
import DeploymentGrid from "../components/deployment-grid/deployment-grid"
import { useSelector } from "react-redux"
import { DepProjectsStateInterface } from "../redux/dep-projects/slice"
import { ProjectGroupStatus } from "../domain"

const DeploymentProjects = () => {
  const { deploymentProjects } = useSelector(
    ({ depProjects }: { depProjects: DepProjectsStateInterface }) => depProjects
  )

  return (
    <div className="deployment-projects-container card">
      {deploymentProjects.map((group: ProjectGroupStatus) => (
        <div key={group.groupName}>
          <h3 className="sub-header">{`${group.groupName} Deployment Projects`}</h3>
          <DeploymentGrid projects={group.projects} />
        </div>
      ))}
    </div>
  )
}

export default DeploymentProjects
