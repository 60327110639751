import {
    fetchUser,
    initialize
} from "./actions";
import {fetchDeploymentProjects, fetchParentTasks} from "../dep-projects/actions";
import { fetchChangeHistory } from "../change-history/actions";

export const globalMiddleware = ({ getState, dispatch }: { getState: any, dispatch: any }) => (next: any) => (action: any) => {

    switch (action.type) {
        case initialize.type:
            dispatch(fetchUser())
            dispatch(fetchDeploymentProjects())
            dispatch(fetchChangeHistory())
            dispatch(fetchParentTasks())
            break
        default:
            break
    }

    next(action)

    return action
}