import {combineReducers, configureStore, createListenerMiddleware, isRejected} from "@reduxjs/toolkit";
import globalReducer from './global/slice'
import {globalMiddleware} from "./global/middleware";
import {globalErrorAction} from "./global/actions";
import depProjectsReducer from './dep-projects/slice'
import changeHistoryReducer from './change-history/slice'
import {PreloadedState} from "redux";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
    matcher: isRejected,
    effect: async (action: any, listenerApi) => {
        if (!!action.payload.status && [401, 404].includes(action.payload.status)) {
            listenerApi.dispatch(globalErrorAction(true))
        }
    },
})

export const rootReducer = combineReducers({
    global: globalReducer,
    depProjects: depProjectsReducer,
    changeHistory: changeHistoryReducer
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware =>
        getDefaultMiddleware()
            .prepend(listenerMiddleware.middleware)
            .concat(
                globalMiddleware
            ))
})

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>

// @ts-ignore
export type AppDispatch = typeof AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector