import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    fetchUser,
    globalErrorAction
} from "./actions";

export interface GlobalStateInterface {
    user?: any
    isLoading: boolean
    error: boolean
}

const initialState: GlobalStateInterface = {
    error: false,
    isLoading: false,
}

const globalSlice = createSlice({
    name: 'global',
    initialState: initialState,
    reducers: {
        setLoading(state, {payload}: PayloadAction<boolean>) {
            state.isLoading = payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(globalErrorAction, (state, {payload}) => {
                state.error = payload
            })
            .addCase(fetchUser.fulfilled, (state, {payload}) => {
                state.user = payload
            })
    }
})

export const { setLoading } = globalSlice.actions
export default globalSlice.reducer