import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ParentTaskInfo, ProjectGroupStatus} from "../../domain";
import {fetchDeploymentProjects, fetchParentTasks} from "./actions";

interface StdChangeInfo {
    projectName: string,
    stdChangeVersion: string,
}

export interface DepProjectsStateInterface {
    deploymentProjects: ProjectGroupStatus[]
    parentTasks: ParentTaskInfo[]
    stdChangeInfo?: StdChangeInfo
    snParentTask?: string
}

const initialState: DepProjectsStateInterface = {
    deploymentProjects: [],
    parentTasks: []
}

const depProjectsSlice = createSlice({
    name: 'depProjects',
    initialState: initialState,
    reducers: {
        setStdChangeInfo(state, {payload}: PayloadAction<StdChangeInfo>) {
            state.stdChangeInfo = {
                projectName: payload.projectName,
                stdChangeVersion: payload.stdChangeVersion
            }
        },
        setParentTask(state, {payload} : PayloadAction<string>) {
              state.snParentTask = payload
        },
        clearStdChangeInfo(state) {
            state.stdChangeInfo = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchDeploymentProjects.fulfilled, (state, {payload}) => {
                state.deploymentProjects = payload
            })
            .addCase(fetchParentTasks.fulfilled, (state, {payload}: PayloadAction<ParentTaskInfo[]>) => {
                state.parentTasks = payload
            })
    }
})

export const {
    setStdChangeInfo,
    setParentTask,
    clearStdChangeInfo
} = depProjectsSlice.actions

export default depProjectsSlice.reducer