import './menu.scss'
import threeDots from '../../static/icons/three-dots-vertical.svg'
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';

interface MenuProps {
    ariaLabel: string,
    menuItems: any[]
}

const Menu = ({ariaLabel, menuItems}: MenuProps) => (
    <div className="menu-container dropdown">
        <Popup
        trigger ={(<button className="btn menu-button" type="button" id="dropdown-menu-button"
                          data-bs-toggle="dropdown" aria-haspopup={true} aria-expanded={false} aria-label={ariaLabel}>
            <img src={threeDots} alt=""/>
        </button>)}
        position="right center"
        closeOnDocumentClick
        >
            <div role="menu" aria-labelledby="dropdown-menu-button">
                { menuItems?.filter(item => !!item).map((item: JSX.Element, index: number) => (
                    <div key={index} className="dropdown-item">
                        {item}
                    </div>
                ))}
            </div>
        </Popup>
    </div>
)

export default Menu