import './deployment-env-info.css'
import {DeploymentEnvironment} from "../../domain";
import Menu from "../menu/menu";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {setStdChangeInfo} from "../../redux/dep-projects/slice"

interface DeploymentEnvInfoProperties {
    env: DeploymentEnvironment | null,
    prodEnv: DeploymentEnvironment | null,
    projectName?: string,
    projectId: string,
    hasLatest: boolean,
}

const cssClass = (info: DeploymentEnvInfoProperties): string => {
    const latestClass: string = info.hasLatest
        ? "has-latest"
        : !!info.env?.deploymentResult && info.env?.deploymentResult?.versionId !== info.prodEnv?.deploymentResult?.versionId
            ? "no-prod"
            : "no-latest"

    const stateClass: string = (!!info.env?.deploymentResult && !!info.env.deploymentResult.deploymentState) ?
        ({
            SUCCESS: 'deploy-succeeded',
            FAILED: 'deploy-failed'
        }) [info.env.deploymentResult.deploymentState] || 'deploy-unknown'
        : 'deploy-unknown'

    return `${latestClass} ${stateClass}`
}

const DeploymentEnvInfo = (props: DeploymentEnvInfoProperties) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const classString = `${cssClass(props)} info-list`

    const buildComparisonUrl = () => {
        const versionIdOnProd =
            props.prodEnv?.deploymentResult?.versionId

        const versionOnProd =
            props.prodEnv?.deploymentResult?.version

        const compareToId =
            props.env?.deploymentResult?.versionId

        if (!versionOnProd || !versionIdOnProd || !compareToId || versionIdOnProd === compareToId) return null

        else return `https://bamboo.liberty.edu/deploy/viewDeploymentVersionJiraIssues.action?compareWithVersion=${encodeURIComponent(
            versionOnProd
        )}&save=Compare&versionId=${compareToId}&deploymentProjectId=${
            props.projectId
        }`
    }

    const comparisonUrl = buildComparisonUrl()

    const isUnknown = () => props.env?.deploymentResult?.deploymentState === "UNKNOWN"

    const stdChange = () => {
        if (!!props.env?.deploymentResult?.version && !!props.projectName) {
            dispatch(setStdChangeInfo({projectName: props.projectName, stdChangeVersion: props.env.deploymentResult.version}))
            navigate("/change-form")
        }
    }

    return (<div className='deploy-env-info-container'>
        {!!props.env?.deploymentResult
            ? (<div className={`${classString} deploy-env-info-container`}>
                <span className={isUnknown() ? "unknown" : ""}>{props.env.deploymentResult.version}</span>
                {!!comparisonUrl ? (
                        <Menu ariaLabel="deploy-menu"
                              menuItems={
                                  [
                                      <a className="simple-button" target="_blank" rel="noreferrer" href={comparisonUrl}>
                                          Compare
                                      </a>,
                                      <button className="simple-button" onClick={() => stdChange()}>Standard Change</button>
                                  ]}
                        />
                    )
                    : (<span></span>)}
            </div>)
            : (<span className={classString}>N/A</span>)
        }
    </div>)

}

export default DeploymentEnvInfo