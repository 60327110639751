import './standard-change-form.scss'
import {useEffect, useState} from "react"
import {ParentTaskInfo, StandardChange} from "../domain"
import axios from "../utils/axios"
import {SERVICE_URL} from "../utils/service-url"
import {useDispatch, useSelector} from "react-redux";
import {clearStdChangeInfo, DepProjectsStateInterface} from "../redux/dep-projects/slice";
import DepProjectRow from "../components/deployment-grid/dep-project-row";

const StandardChangeForm = () => {

    const dispatch = useDispatch()

    const [projectName, setProjectName] = useState("")
    const [versionToDeploy, setVersionToDeploy] = useState("")
    const [svcNowTask, setSvcNowTask] = useState("")
    const [executeDeployment, setExecuteDeployment] = useState(false)
    const [closeTasks, setCloseTasks] = useState(false)
    const [statusMessage, setStatusMessage] = useState("")

    const {
        stdChangeInfo,
        snParentTask,
        parentTasks
    } = useSelector(({depProjects}: { depProjects: DepProjectsStateInterface }) => depProjects)

    useEffect(() => {
        if (!!stdChangeInfo) {
            setProjectName(stdChangeInfo.projectName)
            setVersionToDeploy(stdChangeInfo.stdChangeVersion)
        }
        if (!!snParentTask) setSvcNowTask(snParentTask)
    })

    const projectNameChangeHandler = (event: any) => {
        setProjectName(event.target.value)
    }

    const versionChangeHandler = (event: any) => {
        setVersionToDeploy(event.target.value)
    }

    const svcNowTaskChangeHandler = (event: any) => {
        setSvcNowTask(event.target.value)
    }

    const executeDeploymentChangeHandler = (event: any) => {
        setExecuteDeployment(event.target.checked)
    }

    const closeTasksChangeHandler = (event: any) => {
        setCloseTasks(event.target.checked)
    }

    const createStandardChangeHandler = async (event: any) => {
        event.preventDefault()

        setStatusMessage("Submitting standard change...")

        try {
            const response = await axios.post(`${SERVICE_URL}/api/standard-change`, {
                projectName: projectName,
                version: versionToDeploy,
                parentTask: svcNowTask,
                executeDeployment: executeDeployment,
                autoClose: closeTasks,
            })
            const createdChange: StandardChange = response.data
            setStatusMessage(
                `Successfully created ${createdChange.number} for ${createdChange.parameters.projectName}`
            )
        } catch (error: any) {
            setStatusMessage(
                `Error creating standard change! ${error.response.data.message}`
            )
        }
        dispatch(clearStdChangeInfo())
    }

    return (
        <div className="standard-change-form-outer-container">
            <div className="standard-change-form-container card">
                <h3 className="sub-header">Standard Change Form</h3>
                <form onSubmit={createStandardChangeHandler}>
                    <div>
                        <label className="form-label" htmlFor="projectInput">
                            Project Name
                        </label>
                        <input
                            value={projectName}
                            className="form-control"
                            onChange={projectNameChangeHandler}
                            type="text"
                            id="projectInput"
                        />
                    </div>
                    <div>
                        <label className="form-label" htmlFor="versionInput">
                            Version to Deploy
                        </label>
                        <input
                            value={versionToDeploy}
                            className="form-control"
                            onChange={versionChangeHandler}
                            type="text"
                            id="versionInput"
                        />
                    </div>
                    <div>
                        <label className="form-label" htmlFor="serviceNowTaskInput">
                            Service Now Parent Task
                        </label>
                        <input
                            value={svcNowTask}
                            className="form-control"
                            onChange={svcNowTaskChangeHandler}
                            type="text"
                            id="serviceNowTaskInput"
                        />
                    </div>
                    <div className="form-check">
                        <input
                            checked={executeDeployment}
                            className="form-check-input"
                            onChange={executeDeploymentChangeHandler}
                            type="checkbox"
                            id="executeDeploymentCheckbox"
                        />
                        <label
                            className="form-checkbox-label"
                            htmlFor="executeDeploymentCheckbox"
                        >
                            Execute Deployment
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            checked={closeTasks}
                            className="form-check-input"
                            onChange={closeTasksChangeHandler}
                            type="checkbox"
                            id="closeTasksCheckbox"
                        />
                        <label className="form-checkbox-label" htmlFor="closeTasksCheckbox">
                            Close Tasks after Successful Deployment
                        </label>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary">
                            Submit Standard Change
                        </button>
                    </div>
                    <div>{statusMessage}</div>
                </form>
            </div>
            <div className="standard-change-form-container card">
                <h3 className="sub-header">Active Parent Tasks</h3>
                <table>
                    <thead>
                    <tr>
                        <th>ServiceNow Task</th>
                        <th>Short Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    {parentTasks.map((task: ParentTaskInfo, index: number) => <tr key={`parent-task-${index}`} onClick={() => setSvcNowTask(task.id)}>
                        <td>{task.id}</td>
                        <td>{task.description}</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default StandardChangeForm
