import { ChangeHistoryItem } from "./domain"
import "./change-history.scss"
import { useSelector } from "react-redux"
import { ChangeHistoryStateInterface } from "../../redux/change-history/slice"

const ChangeHistory = () => {

  const { changeHistory } = useSelector(
    ({ changeHistory }: { changeHistory: ChangeHistoryStateInterface }) =>
      changeHistory
  )

  return (
    <div className="change-history-container card">
      <h3>Change History</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Project</th>
            <th>Change Num</th>
            <th>From Version</th>
            <th>To Version</th>
            <th>Deployment Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {changeHistory.map((item, index) => (
            <ChangeHistoryRow key={index} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ChangeHistory

const ChangeHistoryRow = (props: { item: ChangeHistoryItem }) => {
  return (
    <tr>
      <td>{props.item.changeRequest.parameters.projectName}</td>
      <td>{props.item.changeRequest.number}</td>
      <td>{props.item.changeRequest.parameters.versionToReplace?.name}</td>
      <td>{props.item.changeRequest.parameters.versionToDeploy.name}</td>
      <td>{props.item.deploymentStatus}</td>
      <td>{new Date(props.item.entryDate).toLocaleString()}</td>
    </tr>
  )
}
