import axios from "../../utils/axios";
import {AxiosError, AxiosResponse} from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {SERVICE_URL} from "../../utils/service-url";

export const fetchChangeHistory = createAsyncThunk("fetch/changeHistory", (undefined, {rejectWithValue}) =>
   axios
        .get(`${SERVICE_URL}/api/change-history`)
        .then((response: AxiosResponse) => response.data)
        .catch((err: AxiosError) => rejectWithValue({message: err.message, name: err.name, status: err.response?.status}))
)
